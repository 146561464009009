@font-face {
    font-family: "opensans-regular";
    src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
    src:
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "opensans-bold";
    src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
    src:
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
    font-family: Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

:focus {
    outline: none;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

blockquote {
    margin: 1em 40px;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

p,
pre {
    margin: 1em 0;
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}

q:before,
q:after {
    content: "";
    content: none;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

dl,
menu,
ol,
ul {
    margin: 1em 0;
}

dd {
    margin: 0 0 0 40px;
}

menu,
ol,
ul {
    padding: 0 0 0 40px;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

iframe {
    border: none;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: #dc0032;
}

a:hover,
a:focus {
    color: #ff001e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 35px;
    line-height: 45px;
    color: #343f4b;
}

h2 {
    font-size: 20px;
    line-height: 30px;
}

h3 {
    font-size: 16px;
    line-height: 15px;
    margin: 10px 0 5px 0;
}

h4 {
    font-size: 14px;
    line-height: 15px;
    margin: 10px 0 5px 0;
}

h5 {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
}

h6 {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
}

p,
blockquote,
li {
    margin: 0;
}

body {
    color: #343f4b;
}

#site-wrapper {
    width: 960px;
    margin: 0 auto;
    padding: 0 60px;
    position: relative;
    background: #eff3f5;
}

/* Grid
	- grid_x - column width 80*x
	- outer_x - column width 60*x, margin: 0 10px
	- inner_x - column width 60*x, padding: 10px
  --------------------------------------------------------------------------------*/
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
    display: block;
    float: left;
    margin: 0px;
}

.inner_1,
.inner_2,
.inner_3,
.inner_4,
.inner_5,
.inner_6,
.inner_7,
.inner_8,
.inner_9,
.inner_10,
.inner_11,
.inner_12 {
    display: block;
    float: left;
    padding: 10px 10px 0;
    margin: 0 0 10px;
}

.outer_1,
.outer_2,
.outer_3,
.outer_4,
.outer_5,
.outer_6,
.outer_7,
.outer_8,
.outer_9,
.outer_10,
.outer_11,
.outer_12 {
    display: block;
    float: left;
    margin: 0 10px;
}

.container .grid_1 {
    width: 80px;
}
.container .grid_2 {
    width: 160px;
}
.container .grid_3 {
    width: 240px;
}
.container .grid_4 {
    width: 320px;
}
.container .grid_5 {
    width: 400px;
}
.container .grid_6 {
    width: 480px;
}
.container .grid_7 {
    width: 560px;
}
.container .grid_8 {
    width: 640px;
}
.container .grid_9 {
    width: 720px;
}
.container .grid_10 {
    width: 800px;
}
.container .grid_11 {
    width: 880px;
}
.container .grid_12 {
    width: 960px;
}
.container .outer_1,
.container .inner_1 {
    width: 60px;
}
.container .outer_2,
.container .inner_2 {
    width: 140px;
}
.container .outer_3,
.container .inner_3 {
    width: 220px;
}
.container .outer_4,
.container .inner_4 {
    width: 300px;
}
.container .outer_5,
.container .inner_5 {
    width: 380px;
}
.container .outer_6,
.container .inner_6 {
    width: 460px;
}
.container .outer_7,
.container .inner_7 {
    width: 540px;
}
.container .outer_8,
.container .inner_8 {
    width: 620px;
}
.container .outer_9,
.container .inner_9 {
    width: 700px;
}
.container .outer_10,
.container .inner_10 {
    width: 780px;
}
.container .outer_11,
.container .inner_11 {
    width: 860px;
}
.container .outer_12,
.container .inner_12 {
    width: 940px;
}

.push_1 {
    margin-left: 80px;
    margin-right: -80px;
}

.push_2 {
    margin-left: 160px;
    margin-right: -160px;
}

.push_3 {
    margin-left: 240px;
    margin-right: -240px;
}

.push_4 {
    margin-left: 320px;
    margin-right: -320px;
}

.push_5 {
    margin-left: 400px;
    margin-right: -400px;
}

.push_6 {
    margin-left: 480px;
    margin-right: -480px;
}

.push_7 {
    margin-left: 560px;
    margin-right: -560px;
}

.push_8 {
    margin-left: 640px;
    margin-right: -640px;
}

.push_9 {
    margin-left: 720px;
    margin-right: -720px;
}

.push_10 {
    margin-left: 800px;
    margin-right: -800px;
}

.push_11 {
    margin-left: 880px;
    margin-right: -880px;
}

.push_12 {
    margin-left: 960px;
    margin-right: -960px;
}

.pull_1 {
    margin-left: -80px;
}

.pull_2 {
    margin-left: -160px;
}

.pull_3 {
    margin-left: -240px;
}

.pull_4 {
    margin-left: -320px;
}

.pull_5 {
    margin-left: -400px;
}

.pull_6 {
    margin-left: -480px;
}

.pull_7 {
    margin-left: -560px;
}

.pull_8 {
    margin-left: -640px;
}

.pull_9 {
    margin-left: -720px;
}

.pull_10 {
    margin-left: -800px;
}

.pull_11 {
    margin-left: -880px;
}

.pull_12 {
    margin-left: -960px;
}

/* 	========================================
	Global UI elements
	======================================== */
label {
    color: #48535f;
    line-height: 30px;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="url"] {
    height: 28px;
    line-height: 28px;
    padding: 0 0.5em;
    font-size: 15px;
    border: 1px #cad5df solid;
    color: #98a3af;
    background: #fff;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow:
        inset 0 1px 4px 0 rgba(0, 0, 0, 0.2),
        0 1px 0 white;
    -moz-box-shadow:
        inset 0 1px 4px 0 rgba(0, 0, 0, 0.2),
        0 1px 0 white;
    box-shadow:
        inset 0 1px 4px 0 rgba(0, 0, 0, 0.2),
        0 1px 0 white;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="search"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
textarea:hover,
textarea:focus {
    border-color: #b6c1cc;
    color: #848f9b;
    -webkit-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    -moz-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
}

input[type="text"].err,
input[type="password"].err,
input[type="search"].err,
input[type="email"].err,
input[type="url"].err {
    border-color: #dc0032;
    background-color: #ffccd2;
    color: #343f4b;
}

textarea {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #cad5df;
    border-radius: 1px 1px 1px 1px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
    color: #98a3af;
    font-size: 15px;
    height: 28px;
    padding: 5px;
    outline: none;
}

input[placeholder] {
    color: #98a3af;
    line-height: 28px;
}

::-webkit-input-placeholder {
    color: #98a3af;
    line-height: 28px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #98a3af;
    line-height: 28px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #98a3af;
    line-height: 28px;
}

:-ms-input-placeholder {
    color: #98a3af;
    line-height: 28px;
}

/* Styled select
-----------------------------------------------------------------------------*/
.s-hidden {
    display: none;
}

.styledSelect {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font:
        normal 16px Arial,
        Sans-Serif;
    color: #707b87;
    width: 267px;
}

.styledSelect .select {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #cad5df;
    border-radius: 1px 1px 1px 1px;
    box-shadow:
        0 1px 4px 0 rgba(0, 0, 0, 0.2) inset,
        0 1px 0 white;
    color: #98a3af;
    font-size: 15px;
    height: 28px;
    line-height: 28px;
    padding: 0 0.5em;
}

.styledSelect .select:hover {
    border-color: #b6c1cc;
    color: #848f9b;
    -webkit-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    -moz-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
}

.styledSelect .select.active:hover {
    border-color: #b6c1cc;
    color: #848f9b;
    -webkit-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    -moz-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
}

.styledSelect .select:after {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #707b87 transparent transparent transparent;
    position: absolute;
    top: 13px;
    right: 6px;
}

.styledSelect .select:active,
.styledSelect .select.active {
    border-color: #b6c1cc;
    color: #848f9b;
    -webkit-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    -moz-box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
    box-shadow:
        inset 0 1px 6px 0 rgba(0, 0, 0, 0.25),
        0 1px 0 white;
}

.styledSelect .options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: -1px;
    z-index: 999;
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    width: 100%;
    border: 1px solid #cad5df;
    border-top: 0px;
    background-color: white;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.styledSelect .options li {
    margin: 0 10px;
    border-bottom: 1px solid #e7edf3;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: bold;
}

.styledSelect .options li:hover {
    color: #ff0000;
}

a.button,
button:not([class~="vp-button"]),
input[type="submit"],
input[type="reset"] {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 30px;
    min-width: 60px;
    border: 1px #dc0032 solid;
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 2px;
    color: white;
    text-shadow: 0 -1px 0 #64001e;
    background-color: #ff001e;
    background-image: linear-gradient(to top, #ff001e, #dc0032);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#ff001e, EndColorStr=#dc0032);
    box-shadow: inset 0 1px 2px 0 rgba(255, 255, 255, 0.5);
}

a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
a.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
    background-color: #c0002f;
    background-image: linear-gradient(to top, #c0002f, #de001c);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#c0002f, EndColorStr=#de001c);
}

a.button:active,
button:active,
input[type="submit"]:active,
input[type="reset"]:active {
    background-color: #dc0032;
    background-image: linear-gradient(to top, #dc0032, #ff001e);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#dc0032, EndColorStr=#ff001e);
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

a.button.bright,
button.bright,
input[type="submit"].bright,
input[type="reset"].bright {
    color: #707b87;
    border-color: #cad5df;
    text-shadow: 0 1px 0 white;
    background-color: #fbfdfd;
    background-image: linear-gradient(to top, #fbfdfd, #dee6ee);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#fbfdfd, EndColorStr=#dee6ee);
}

a.button.bright:hover,
button.bright:hover,
input[type="submit"].bright:hover,
input[type="reset"].bright:hover,
a.button.bright:focus,
button.bright:focus,
input[type="submit"].bright:focus,
input[type="reset"].bright:focus {
    background-color: #dee6ee;
    background-image: linear-gradient(to top, #dee6ee, #cad5df);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#dee6ee, EndColorStr=#cad5df);
}

a.button.bright:active,
button.bright:active,
input[type="submit"].bright:active,
input[type="reset"].bright:active {
    background-color: #dee6ee;
    background-image: linear-gradient(to top, #dee6ee, #fbfdfd);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#dee6ee, EndColorStr=#fbfdfd);
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

a.button.dark,
button.dark,
input[type="submit"].dark,
input[type="reset"].dark {
    color: white;
    border-color: #48535f;
    text-shadow: 0 -1px 0 #343f4b;
    background-color: #707b87;
    background-image: linear-gradient(to top, #707b87, #48535f);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#707b87, EndColorStr=#48535f);
}

a.button.dark:hover,
button.dark:hover,
input[type="submit"].dark:hover,
input[type="reset"].dark:hover,
a.button.dark:focus,
button.dark:focus,
input[type="submit"].dark:focus,
input[type="reset"].dark:focus {
    background-color: #5c6773;
    background-image: linear-gradient(to top, #5c6773, #343f4b);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#5c6773, EndColorStr=#343f4b);
}

a.button.dark:active,
button.dark:active,
input[type="submit"].dark:active,
input[type="reset"].dark:active {
    background-color: #48535f;
    background-image: linear-gradient(to top, #48535f, #707b87);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#48535f, EndColorStr=#707b87);
    box-shadow: 0 1px 3px 0 inset rgba(0, 0, 0, 0.5);
}

a.button.disabled,
button.disabled,
button[disabled="disabled"],
input[disabled],
a.button.dark.disabled,
button.dark.disabled,
button[disabled="disabled"].dark,
input[disabled].dark,
a.button.bright.disabled,
button.bright.disabled,
button[disabled="disabled"].bright,
input[disabled].bright,
a.button.disabled:hover,
button.disabled:hover,
button[disabled="disabled"]:hover,
input[disabled]:hover,
a.button.dark.disabled:hover,
button.dark.disabled:hover,
button[disabled="disabled"].dark:hover,
input[disabled].dark:hover,
a.button.bright.disabled:hover,
button.bright.disabled:hover,
button[disabled="disabled"].bright:hover,
input[disabled].bright:hover,
a.button.disabled:focus,
button.disabled:focus,
button[disabled="disabled"]:focus,
input[disabled]:focus,
a.button.dark.disabled:focus,
button.dark.disabled:focus,
button[disabled="disabled"].dark:focus,
input[disabled].dark:focus,
a.button.bright.disabled:focus,
button.bright.disabled:focus,
button[disabled="disabled"].bright:focus,
input[disabled].bright:focus {
    cursor: default;
    color: #cad5df;
    border-color: #dee6ee;
    text-shadow: 0 1px 0 white;
    background-color: white;
    background-image: linear-gradient(to top, white, #e9eff4);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=white, EndColorStr=#e9eff4);
}

.error {
    border: 1px solid #dc0032;
    color: #dc0032;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 20px;
    padding: 10px;
    background-color: #f07878;
    border-radius: 3px;
}

/* 	=======================================
	GENERAL STYLESHEET
	======================================= */
body {
    background: transparent url(../../../images/ahacz/bg.png) repeat 0 0;
    overflow-anchor: none;
}

/*	===============================
  	Formulář pro odesílání tipů
    =============================== */
.dejteNamTip label {
    display: block;
    line-height: 30px;
}
.dejteNamTip form > div {
    margin-top: 30px;
    clear: both;
}
.dejteNamTip input.text,
.dejteNamTip textarea {
    padding-left: 5px;
    padding-right: 5px;
}
.dejteNamTip div.name {
    width: 460px;
}
.dejteNamTip div.name input {
    width: 448px;
    display: block;
}
.dejteNamTip div.contacts div {
    float: left;
    width: 220px;
}
.dejteNamTip div.contacts div.email {
    margin-right: 20px;
}
.dejteNamTip div.contacts input {
    width: 208px;
    display: block;
}
.dejteNamTip div.message {
    clear: left;
    width: 100%;
}
.dejteNamTip div.message textarea {
    width: 448px;
    height: 120px;
}
.dejteNamTip div.buttons {
    margin-top: 0;
}
.dejteNamTip p.thanks {
    margin: 30px 0;
    text-align: center;
    font: bold 16px/1 Arial;
}

.sharingButSpecial {
    z-index: 1;
    position: absolute;
    right: 13px;
    bottom: 0px;
}
.sharingButSpecial .sharingButtons.vertical a {
    border-top: 0px !important;
    height: 30px;
}
.neprehledneteArt {
    padding: 3px 22px 3px 0 !important;
}
/*#main-content .articleItemSmall a h4 {
  margin: 0;
  padding: 3px 10px 3px 0;
  height: 64px;
  font: bold 14px/16px Arial, Helvetica;
  color: #343f4b;
  background: #fff;
  -moz-transition: all 0.05s ease-out;
  -o-transition: all 0.05s ease-out;
  -webkit-transition: all 0.05s ease-out;
  -ms-transition: all 0.05s ease-out;
  transition: all 0.05s ease-out;
  width:275px !important;
  }*/
.sharingButtons {
    position: relative;
}
.sharingButtons .icon {
    margin: 5px auto 0 auto;
}
.sharingButtons .icon.facebook {
    width: 17px;
    height: 18px;
    margin-top: 13px;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat -105px -3px;
}
.sharingButtons a:hover .icon.facebook {
    background-position: -133px -3px;
}
.sharingButtons .icon.twitter {
    width: 23px;
    height: 16px;
    margin-top: 15px;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat -105px -28px;
}
.sharingButtons a:hover .icon.twitter {
    background-position: -133px -28px;
}
.sharingButtons .icon.comments {
    width: 15px;
    height: 13px;
    margin-top: 13px;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat -105px -53px;
}
.sharingButtons a:hover .icon.comments {
    background-position: -133px -53px;
}
.sharingButtons a {
    color: #5c6773;
    text-decoration: none;
}
.sharingButtons a small {
    font-size: 9px;
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 24px;
    text-align: center;
}
.sharingButtons a small:hover {
    text-decoration: underline;
}
.sharingButtons a:hover,
.sharingButtons a:focus {
    color: #dc0032;
}

.sharingButtons.vertical {
    width: 24px;
}
.sharingButtons.vertical a {
    display: block;
    border-top: 1px #b6c1cc solid;
    height: 44px;
    width: 24px;
    text-decoration: none !important;
}
.sharingButtons.vertical .fb-like-container {
    position: absolute;
    background: #f1f4f7;
    background: rgba(241, 244, 247, 0.95);
    width: 125px;
    height: 19px;
    padding: 15px 15px;
    right: 24px;
    top: 0px;
    z-index: 2;
    display: none;
}
.sharingButtons.vertical .fb-like-container .loader {
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    background: transparent url(../../../images/ahacz/loader.gif) no-repeat 0 0;
}

.sharingButtons.horizontal {
    height: 40px;
    width: 100%;
}
.sharingButtons.horizontal a {
    display: block;
    float: left;
    width: 46px;
    height: 20px;
    margin: 10px 0;
    border-right: 1px #b6c1cc solid;
}
.sharingButtons.horizontal .icon {
    margin-top: 2px;
}
.sharingButtons.horizontal a.comments {
    border-right: 0;
}
.sharingButtons.horizontal small {
    left: 104px;
}
.sharingButtons.horizontal .fb-like-container {
    position: absolute;
    background: white;
    width: 110px;
    height: 45px;
    padding: 30px 15px;
    left: 0;
    bottom: 30px;
    z-index: 2;
    display: none;
}
.sharingButtons.horizontal .fb-like-container .loader {
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    background: transparent url(../../../images/ahacz/loader.gif) no-repeat 0 0;
}

.hpMainArticle .sharingButtons.horizontal .fb-like-container {
    background: #f1f4f7;
    background: rgba(241, 244, 247, 0.95);
}

.fb-like {
    display: none;
    overflow: visible;
}

.sharingButtons.classic {
    clear: both;
    margin: 20px 0;
    height: 20px;
}
.sharingButtons.classic .fb,
.sharingButtons.classic .twitter {
    float: left;
    margin-right: 10px;
}
.sharingButtons.classic .twitter {
    margin-right: 0;
}
.sharingButtons.classic .button {
    font-size: 12px;
    line-height: 1;
    padding: 3px 0.5em;
    height: auto;
}
.sharingButtons.classic .button .icon.comments {
    float: left;
    margin: 0 0.5em 0 0;
}
.sharingButtons.classic .button:hover,
.sharingButtons.classic .button:focus {
    color: #707b87;
}
.sharingButtons.classic .fb-like {
    display: block;
}

.sharingButtons .facebook .fb-like-container .fb_iframe_widget,
.sharingButtons .facebook .fb-like-container .fb_iframe_widget span,
.sharingButtons .facebook .fb-like-container .fb_iframe_widget span iframe {
    width: 110px !important;
}

.headingArrow {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -12px no-repeat transparent;
    display: block;
    float: right;
    height: 12px;
    margin-right: 14px;
    margin-top: 14px;
    width: 12px;
}

.loadMore {
    text-align: center;
    margin: 30px 0;
    background: transparent url(../../../images/ahacz/ajaxloader-line.png) no-repeat center center;
}
.loadMore a.load {
    background: white;
    color: #343f4b;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font:
        bold 14px/1 Arial,
        Helvetica;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 5px 20px 5px 40px;
}
.loadMore a.load span {
    background: url("../../../images/ahacz/elements.png?v=12") no-repeat scroll -222px -48px transparent;
    display: block;
    float: right;
    height: 12px;
    margin-left: 12px;
    /*md*/
    width: 12px;
}
.loadMore a.load:hover,
.loadMore a.load:focus {
    text-decoration: underline;
    color: #dc0032;
}

#breadcrumbs {
    border-bottom: 1px #cad5df solid;
    font:
        12px/1 Arial,
        Helvetica;
    padding: 4px 0 13px 0;
    color: #707b87;
    margin-bottom: 15px;
}
#breadcrumbs a {
    color: #5c6773;
    text-decoration: none;
}
#breadcrumbs a:hover,
#breadcrumbs a:focus {
    text-decoration: underline;
}
#breadcrumbs span {
    margin: 0 5px;
    color: #b6c1cc;
}

.searchForm {
    position: relative;
    float: right;
}
.searchForm div .text {
    float: right;
    padding: 0 5px;
    width: 240px;
    border-radius: 1px 0 0 1px;
    color: #48535f;
}
.searchForm div .submit {
    float: right;
    width: 128px;
    border-radius: 0 1px 1px 0;
}

.searchForm.results div .text {
    width: 320px;
}

.formInfo {
    line-height: 30px;
    margin-top: 30px;
}

h1.formInfo {
    font-size: 16px;
    font-weight: 400;
}

.actualCover {
    position: absolute;
    left: -18px;
    top: 361px;
    width: 230px;
    height: 253px;
    overflow: hidden;
}
.actualCover a {
    display: block;
}
/*
.actualCover img {
  position: absolute;
  left: 35px;
  top: 70px;
  border: 4px #fff solid;
  width: 140px;
  height: auto;
  -moz-transform: rotate(-9deg);
  -o-transform: rotate(-9deg);
  -webkit-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
  -webkit-box-shadow:  -12px 10px 25px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow:  -12px 10px 25px -10px rgba(0, 0, 0, 0.5);
  box-shadow:  -12px 10px 25px -10px rgba(0, 0, 0, 0.5);
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
*/
/* .actualCover:hover img */
.actualCover img {
    position: absolute;
    top: 1px;
    left: 20px;
    width: 140px;
    border: 4px #fff solid;
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

#main-header {
    position: relative;
    height: auto;
    padding: 0 0 30px 0;
}
#main-header #headerForms {
    height: auto;
}
#main-header #headerForms .searchForm input[type="submit"] {
    width: 135px;
}
#main-header .loginForm {
    float: left;
}
#main-header .loginForm p {
    font:
        bold 9px/30px Arial,
        Helvetica;
    color: #b6c1cc;
}
#main-header .loginForm p a {
    color: #707b87;
    text-decoration: none;
}
#main-header .loginForm p a.link-underline {
    text-decoration: underline;
}
#main-header .loginForm p a.link-underline:hover {
    text-decoration: none;
}
#main-header .loginForm p a:hover,
#main-header .loginForm p a:focus {
    text-decoration: underline;
}
#main-header .loginForm form {
    position: relative;
}
#main-header .loginForm form div {
    float: left;
}
#main-header .loginForm form div input {
    display: block;
    width: 140px;
    padding: 0 5px;
}
#main-header .loginForm form div input.submit {
    width: 150px;
}
#main-header .user {
    line-height: 30px;
}
#main-header #main-logo {
    position: relative;
    float: left;
    width: 240px;
    height: 140px;
    padding: 0 0 10px 0;
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat left bottom;
}
#main-header #main-logo .versionSwitch {
    position: absolute;
    left: 49px;
    bottom: 13px;
    font-size: 11px;
    color: #64001e;
}
#main-header #main-logo .versionSwitch a {
    color: white;
    text-decoration: none;
}
#main-header #main-logo .versionSwitch a:hover,
#main-header #main-logo .versionSwitch a:focus {
    text-decoration: underline;
}
#main-header #header-articles {
    width: 710px;
    float: right;
}
#main-header #header-articles a {
    display: block;
    color: #343f4b;
    text-decoration: none;
}
#main-header #header-articles a:hover h3,
#main-header #header-articles a:focus h3 {
    color: #dc0032;
}
#main-header #header-articles a:hover figcaption,
#main-header #header-articles a:focus figcaption {
    -webkit-box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.7);
}
#main-header #header-articles article {
    width: 335px;
    margin: 0 10px;
    float: left;
}
#main-header #header-articles .articleItem {
    background: #fff;
}
#main-header #header-articles .articleItem .articleMeta {
    font-size: 12px;
    line-height: 30px;
    height: 30px;
    color: #707b87;
}
#main-header #header-articles .articleItem .articleMeta strong,
#main-header #header-articles .articleItem .articleMeta a {
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15ex;
    color: #ff001e;
}
#main-header #header-articles .articleItem .articleMeta a:hover {
    text-decoration: underline;
}
#main-header #header-articles .articleItem h3 {
    margin: 0;
    padding: 0 5px;
    height: 110px;
    font:
        bold 15px/18px Arial,
        Helvetica;
    word-break: break-word;
}
#main-header #header-articles .articleItem h3:hover {
    text-decoration: underline;
}
#main-header #header-articles .articleItem h3.title-ellipsis {
    height: 127px;
    padding-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
}
#main-header #header-articles .articleItem .imageWrapper {
    width: 220px;
    float: left;
    margin-right: 0;
}
#main-header #header-articles .articleItem .imageWrapper img {
    position: relative;
    left: -10px;
    height: 140px;
}
#main-header #header-articles .articleItem figcaption {
    -webkit-box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.6);
    box-shadow: 0 5px 60px -20px rgba(0, 0, 0, 0.6);
}
#main-header #header-articles .articleItem.first {
    margin: 0;
}
#main-header #headerBottomLine {
    clear: both;
}
#main-header .fb {
    display: block;
    width: 26px;
    height: 26px;
    margin: -3px 10px 10px 17px;
    float: left;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat -103px -77px;
}
#main-header a.fb:hover,
#main-header a.fb:focus {
    background-position: -131px -77px;
}
#main-header .day,
#main-header .feast {
    float: left;
    color: #5c6773;
    font: 12px/1 Arial;
    text-align: right;
    padding-top: 12px;
}
#main-header .day strong,
#main-header .feast strong {
    text-transform: capitalize;
}
#main-header .day {
    width: 187px;
}
#main-header .feast {
    float: right;
    text-align: right;
    margin-right: 10px;
}
#main-header .feast a {
    font-weight: 700;
    text-decoration: none;
    color: #ff001e;
}
#main-header .feast a:hover,
#main-header .feast a:focus {
    text-decoration: underline;
}
#main-header .feast.homepage {
    margin-right: 170px;
}

.main-menu {
    position: relative;
}

#main-menu {
    background: transparent url(../../../images/ahacz/menu-shadow.png) no-repeat 145px 0;
    min-height: 565px;
}
#main-menu .icon {
    display: block;
    margin: 5px;
}
#main-menu .ribbon {
    display: none;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat 0 0;
}
#main-menu nav .menu .icon {
    height: 36px;
    width: 36px;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat 0 0;
    float: left;
    margin: 10px 5px 10px -7px;
}
#main-menu nav .menu .zhave-drby .icon {
    background-position: 0px 0px;
}
#main-menu nav .menu .trapasy .icon {
    background-position: 0px -36px;
}
#main-menu nav .menu .musite-vedet .icon {
    background-position: 0px -72px;
}
#main-menu nav .menu .recepty .icon {
    background-position: 0px -108px;
}
#main-menu nav .menu .laska-a-sex .icon {
    background-position: 0px -144px;
}
#main-menu nav .menu .krimi-pribehy .icon {
    background-position: 0px -180px;
}
#main-menu nav .menu .sexy-dekolt .icon {
    background-position: 0px -216px;
}
#main-menu nav .menu .retro-skandaly-aneb-legendy-pod-lupou .icon {
    background-position: 0px -380px;
}
#main-menu nav .menu .zhave-drby:hover .icon,
#main-menu nav .menu .zhave-drby:focus .icon,
#main-menu nav .menu .zhave-drby:active .icon,
#main-menu nav .menu .active.zhave-drby .icon {
    background-position: -36px 0px;
}
#main-menu nav .menu .trapasy:hover .icon,
#main-menu nav .menu .trapasy:focus .icon,
#main-menu nav .menu .trapasy:active .icon,
#main-menu nav .menu .active.trapasy .icon {
    background-position: -36px -36px;
}
#main-menu nav .menu .musite-vedet:hover .icon,
#main-menu nav .menu .musite-vedet:focus .icon,
#main-menu nav .menu .musite-vedet:active .icon,
#main-menu nav .menu .active.musite-vedet .icon {
    background-position: -36px -72px;
}
#main-menu nav .menu .recepty:hover .icon,
#main-menu nav .menu .recepty:focus .icon,
#main-menu nav .menu .recepty:active .icon,
#main-menu nav .menu .active.recepty .icon {
    background-position: -36px -108px;
}
#main-menu nav .menu .laska-a-sex:hover .icon,
#main-menu nav .menu .laska-a-sex:focus .icon,
#main-menu nav .menu .laska-a-sex:active .icon,
#main-menu nav .menu .active.laska-a-sex .icon {
    background-position: -36px -144px;
}
#main-menu nav .menu .krimi-pribehy:hover .icon,
#main-menu nav .menu .krimi-pribehy:focus .icon,
#main-menu nav .menu .krimi-pribehy:active .icon,
#main-menu nav .menu .active.krimi-pribehy .icon {
    background-position: -36px -180px;
}
#main-menu nav .menu .sexy-dekolt:hover .icon,
#main-menu nav .menu .sexy-dekolt:focus .icon,
#main-menu nav .menu .sexy-dekolt:active .icon,
#main-menu nav .menu .active.sexy-dekolt .icon {
    background-position: -36px -216px;
}
#main-menu nav .menu .retro-skandaly-aneb-legendy-pod-lupou:hover .icon,
#main-menu nav .menu .retro-skandaly-aneb-legendy-pod-lupou:focus .icon,
#main-menu nav .menu .retro-skandaly-aneb-legendy-pod-lupou:active .icon,
#main-menu nav .menu .active.retro-skandaly-aneb-legendy-pod-lupou .icon {
    background-position: -36px -380px;
}
#main-menu nav .menu .vanocni-pohadky .icon {
    background-position: 0px -254px;
}
#main-menu nav .menu .vanocni-pohadky:hover .icon,
#main-menu nav .menu .vanocni-pohadky:focus .icon,
#main-menu nav .menu .vanocni-pohadky:active .icon,
#main-menu nav .menu .active.vanocni-pohadky .icon {
    background-position: -36px -254px;
}
#main-menu nav .menu .kralovna-plesu .icon {
    background: transparent url(../../../images/ahacz/ico-kralovna.png?v=1) no-repeat 0 0;
}
#main-menu nav .menu .kralovna-plesu .ribbon {
    background: transparent url(../../../images/ahacz/ribbon-left-darkblue.png) no-repeat 0 0;
}
#main-menu nav .menu .active.kralovna-plesu .icon,
#main-menu nav .menu .kralovna-plesu:hover .icon,
#main-menu nav .menu .kralovna-plesu:focus .icon,
#main-menu nav .menu .kralovna-plesu:active .icon {
    background-position: 0 -36px;
}

#main-menu nav .menu .video .icon {
    background-position: -6px -298px;
}
#main-menu nav .menu .video:hover .icon,
#main-menu nav .menu .video:focus .icon,
#main-menu nav .menu .video:active .icon,
#main-menu nav .menu .video.active .icon {
    background-position: -43px -298px;
}

#main-menu ul.menu {
    margin: 0 0 17px 0;
    padding: 0;
}
#main-menu ul.menu li {
    padding: 0;
    margin: 0;
    background: transparent url(../../../images/ahacz/menu-line.png) no-repeat left bottom;
}
#main-menu ul.menu li a {
    display: block;
    position: relative;
    height: 60px;
    /* c */
    padding: 0 0 0 10px;
    color: #5c6773;
    font:
        bold 15px/60px Arial,
        Helvetica;
    text-decoration: none;
    background-position: 0 13px;
    background-repeat: no-repeat;
}
#main-menu ul.menu li.vanocni-pohadky a {
    font:
        bold 12px/60px Arial,
        Helvetica;
}
#main-menu ul.menu li.kralovna-plesu {
    letter-spacing: -1px;
}
#main-menu ul.menu li a:hover,
#main-menu ul.menu li a:focus,
#main-menu ul.menu li.active a,
#main-menu ul.menu li a:active {
    position: relative;
    color: #fff;
    background-color: #ff001e;
    background-position: 10px 13px;
    -webkit-box-shadow: inset -3px 0 6px -3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: inset -3px 0 6px -3px rgba(0, 0, 0, 0.6);
    box-shadow: inset -3px 0 6px -3px rgba(0, 0, 0, 0.6);
}
#main-menu ul.menu li a:hover .ribbon,
#main-menu ul.menu li a:focus .ribbon,
#main-menu ul.menu li.active a .ribbon,
#main-menu ul.menu li a:active .ribbon {
    display: block;
}
#main-menu ul.menu li.kralovna-plesu a:hover,
#main-menu ul.menu li.kralovna-plesu a:focus,
#main-menu ul.menu li.active.kralovna-plesu a {
    background-color: #0a53a8;
}
#main-menu ul.menu li.active a {
    background-color: #cc0018;
}

#main-content .articlePerex {
    margin: 10px 0 20px 0;
    font:
        bold 16px/22px Arial,
        Helvetica;
}
#main-content .hpMainArticle,
#main-content .articleItemBig {
    /* TODO: Odstranit po vygenervání správných velikostí obrázků */
}
#main-content .hpMainArticle .articleMeta,
#main-content .articleItemBig .articleMeta {
    font-size: 12px;
    line-height: 30px;
    color: #707b87;
}
#main-content .hpMainArticle .articleMeta strong,
#main-content .hpMainArticle .articleMeta a,
#main-content .articleItemBig .articleMeta strong,
#main-content .articleItemBig .articleMeta a {
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15ex;
    color: #ff001e;
}
#main-content .hpMainArticle .articleMeta a:hover,
#main-content .articleItemBig .articleMeta a:hover {
    text-decoration: underline;
}
#main-content .hpMainArticle h1,
#main-content .hpMainArticle h2,
#main-content .articleItemBig h1,
#main-content .articleItemBig h2 {
    min-height: 76px;
    overflow: hidden;
    line-height: 38px;
}
#main-content .hpMainArticle h1 {
    margin: 5px 0px;
}
#main-content .hpMainArticle h2 {
    font-size: 35px;
}
#main-content .hpMainArticle h1 a,
#main-content .hpMainArticle h2 a,
#main-content .articleItemBig h1 a,
#main-content .articleItemBig h2 a {
    color: #343f4b;
    text-decoration: none;
}
#main-content .hpMainArticle h1 a:hover,
#main-content .hpMainArticle h1 a:focus,
#main-content .hpMainArticle h2 a:hover,
#main-content .hpMainArticle h2 a:focus,
#main-content .articleItemBig h1 a:hover,
#main-content .articleItemBig h1 a:focus,
#main-content .articleItemBig h2 a:hover,
#main-content .articleItemBig h2 a:focus {
    text-decoration: underline;
    color: #ff001e;
}
#main-content .hpMainArticle .imageWrapper,
#main-content .articleItemBig .imageWrapper {
    width: 620px;
}
#main-content .hpMainArticle .imageWrapper img,
#main-content .articleItemBig .imageWrapper img {
    width: 620px;
    height: 390px;
}
#main-content .hpMainArticle .readMore,
#main-content .articleItemBig .readMore {
    font:
        bold 10px/15px Arial,
        Helvetica;
    color: #98a3af;
    text-decoration: none;
    margin: 15px 0;
}
#main-content .hpMainArticle .readMore:hover,
#main-content .hpMainArticle .readMore:focus,
#main-content .articleItemBig .readMore:hover,
#main-content .articleItemBig .readMore:focus {
    color: #dc0032;
    text-decoration: underline;
}
#main-content .articleItemBig.forumHeader h1 {
    height: auto;
}
#main-content .hpMainArticle .readMore {
    line-height: 20px;
    margin: 11px 0;
}
#main-content .hpMainArticle .readMore span {
    margin-top: 4px;
}
#main-content .hpMainArticle footer {
    border-bottom: 1px #cad5df solid;
}
#main-content .articleItemBig {
    position: relative;
}
#main-content .articleItemBig .sharingButtons {
    position: absolute;
    right: 0;
    top: 10px;
}
#main-content .articleItemBig .articleMeta,
#main-content .articleItemBig h1,
#main-content .articleItemBig h2 {
    padding-right: 30px;
}
#main-content .articleItemBig h1,
#main-content .articleItemBig h2 {
    min-height: 114px;
    font:
        bold 35px/38px Arial,
        Helvetica;
}
#main-content .articleItemBig .articleImage {
    margin-left: -10px;
}
#main-content .articleItemBig .articleImage img {
    width: 100%;
}
#main-content .sectionHeading,
#main-content .articles h2,
#main-content .asideArticles h3 {
    width: 100%;
    margin: 0;
    position: relative;
    font:
        bold 16px/42px "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
}
#main-content .sectionHeading a,
#main-content .sectionHeading div,
#main-content .articles h2 a,
#main-content .articles h2 div,
#main-content .asideArticles h3 a,
#main-content .asideArticles h3 div {
    display: block;
    height: 40px;
    line-height: 42px;
    width: 100%;
    padding-left: 10px;
    background-color: #ff001e;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.1s ease-in-out;
}

#main-content .asideArticles.nakupy a:hover h4 strong {
    color: #fff;
}

#main-content .sectionHeading a span,
#main-content .sectionHeading div span,
#main-content .articles h2 a span,
#main-content .articles h2 div span,
#main-content .asideArticles h3 a span,
#main-content .asideArticles h3 div span {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -12px no-repeat transparent;
    display: block;
    float: right;
    height: 12px;
    margin-right: 14px;
    margin-top: 14px;
    width: 12px;
}
#main-content .sectionHeading a:hover,
#main-content .sectionHeading a:focus,
#main-content .articles h2 a:hover,
#main-content .articles h2 a:focus,
#main-content .asideArticles h3 a:hover,
#main-content .asideArticles h3 a:focus {
    background-color: #cc0018;
}
#main-content .tmavy .sectionHeading a,
#main-content .tmavy .sectionHeading div,
#main-content .tmavy .articles h2 a,
#main-content .tmavy .articles h2 div,
#main-content .tmavy .asideArticles h3 a,
#main-content .tmavy .asideArticles h3 div,
#main-content .dark .sectionHeading a,
#main-content .dark .sectionHeading div,
#main-content .dark .articles h2 a,
#main-content .dark .articles h2 div,
#main-content .dark .asideArticles h3 a,
#main-content .dark .asideArticles h3 div {
    background-color: #343f4b;
}
#main-content .tmavy .sectionHeading a:hover,
#main-content .tmavy .sectionHeading a:focus,
#main-content .tmavy .articles h2 a:hover,
#main-content .tmavy .articles h2 a:focus,
#main-content .tmavy .asideArticles h3 a:hover,
#main-content .tmavy .asideArticles h3 a:focus,
#main-content .dark .sectionHeading a:hover,
#main-content .dark .sectionHeading a:focus,
#main-content .dark .articles h2 a:hover,
#main-content .dark .articles h2 a:focus,
#main-content .dark .asideArticles h3 a:hover,
#main-content .dark .asideArticles h3 a:focus {
    background-color: #48535f;
}
#main-content .tmavy .sectionHeading,
#main-content .tmavy .articles h2,
#main-content .dark .sectionHeading,
#main-content .dark .articles h2 {
    background-image: url(../../../images/ahacz/ribbon-left-black.png);
}
#main-content .tmavy .asideArticles h3,
#main-content .dark .asideArticles h3 {
    background-image: url(../../../images/ahacz/ribbon-right-black.png);
}
#main-content .svetly .sectionHeading a,
#main-content .svetly .sectionHeading div,
#main-content .svetly .articles h2 a,
#main-content .svetly .articles h2 div,
#main-content .svetly .asideArticles h3 a,
#main-content .svetly .asideArticles h3 div,
#main-content .bright .sectionHeading a,
#main-content .bright .sectionHeading div,
#main-content .bright .articles h2 a,
#main-content .bright .articles h2 div,
#main-content .bright .asideArticles h3 a,
#main-content .bright .asideArticles h3 div {
    background-color: #cad5df;
    color: #707b87;
}
#main-content .svetly .sectionHeading a:hover,
#main-content .svetly .sectionHeading a:focus,
#main-content .svetly .articles h2 a:hover,
#main-content .svetly .articles h2 a:focus,
#main-content .svetly .asideArticles h3 a:hover,
#main-content .svetly .asideArticles h3 a:focus,
#main-content .bright .sectionHeading a:hover,
#main-content .bright .sectionHeading a:focus,
#main-content .bright .articles h2 a:hover,
#main-content .bright .articles h2 a:focus,
#main-content .bright .asideArticles h3 a:hover,
#main-content .bright .asideArticles h3 a:focus {
    background-color: #b6c1cc;
    color: #848f9b;
}
#main-content .svetly .sectionHeading,
#main-content .svetly .articles h2,
#main-content .bright .sectionHeading,
#main-content .bright .articles h2 {
    background-image: url(../../../images/ahacz/ribbon-left-midgrey.png);
}
#main-content .svetly .asideArticles h3,
#main-content .bright .asideArticles h3 {
    background-image: url(../../../images/ahacz/ribbon-right-midgrey.png);
}
#main-content .sectionHeading,
#main-content .articles h2 {
    padding: 0 0 10px 0;
    margin: 0 10px;
    width: auto;
    left: -10px;
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat left bottom;
}
#main-content .asideArticles h3 {
    padding: 0 10px 10px 0;
    background: transparent url(../../../images/ahacz/ribbon-right-red.png) no-repeat right bottom;
}
#main-content .sectionHeading {
    margin-top: 20px;
    margin-bottom: -20px;
}
#main-content .sectionHeading.formHeading {
    position: relative;
    margin: 0 0 0 180px;
    padding: 0 0 0 10px;
    background: #5c6773 none;
}
#main-content .sectionHeading.formHeading span.ribbon {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: transparent url(../../../images/ahacz/ribbon-left-grey.png) no-repeat 0 0;
    left: 0;
    bottom: -10px;
}
#main-content .sectionHeading.formHeading.searchTitle {
    position: relative;
    margin: 0 0 0 180px;
    padding: 0 0 0 10px;
    background: #5c6773 none;
}
.container .grid_6.result {
    position: relative;
    left: 10px;
}
#main-content .articleItemMed {
    background: white;
    height: 255px;
    margin-bottom: 25px;
    position: relative;
}
#main-content .articleItemMed a {
    color: #343f4b;
    text-decoration: none;
}
#main-content .articleItemMed .articleMetaClear,
#main-content .articleItemMed a .articleMeta {
    height: 30px;
    color: #dc0032;
    font:
        bold 10px/30px Arial,
        Helvetica;
    letter-spacing: 0.2ex;
    text-transform: uppercase;
}
#main-content .articleItemMed .articleMetaAd {
    font-size: 12px;
    color: #343f4b;
    text-transform: none;
}
#main-content .articleItemMed a .articleMeta strong,
#main-content .articleItemMed .articleMetaAd strong {
    padding: 0 0 0 10px;
}
#main-content .articleItemMed a h3,
.h2-wiki-title {
    margin: 0;
    padding: 10px;
    font:
        bold 14px/15px Arial,
        Helvetica;
    height: 75px;
}
#main-content .articleItemMed a:hover,
#main-content .articleItemMed a:focus {
    color: #dc0032;
}
#main-content .articleItemMed a h3:hover,
#main-content .articleItemMed a h3:focus,
.h2-wiki-title:focus {
    text-decoration: underline;
}
#main-content .articleItemMed a.headerLink:hover,
#main-content .articleItemMed a.headerLink:focus {
    color: #ff001e;
    text-decoration: underline;
}
#main-content .articleItemMed a.headerLink:hover .articleMeta,
#main-content .articleItemMed a.headerLink:focus .articleMeta {
    color: #ff001e;
}
#main-content .articleItemMed .sharingButtons {
    background-color: #fff;
}
#main-content .articleItemMed .imageWrapper {
    height: 90px;
}
#main-content .articleItemMed a .articleMeta.promoted {
    position: relative;
    width: 150px;
    left: -10px;
    color: #fff;
    background-color: #ff001e;
}
#main-content .articleItemMed a .articleMeta.promoted .ribbon {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat 0 0;
}
#main-content .articleItemMed a:hover .articleMeta.promoted,
#main-content .articleItemMed a:focus .articleMeta.promoted {
    text-decoration: underline;
    color: #fff;
}
#main-content .articleItemMed.last {
    margin-right: 0;
}
#main-content .articleItemMed.search {
    height: 240px;
}
#main-content .articleItemMed.search a h3,
.h2-wiki-title {
    height: 90px;
}
#main-content .results .sharingButtons.horizontal .fb-like-container {
    height: 60px;
}
#main-content .articleItem {
    position: relative;
    clear: both;
}
#main-content .articleItem .articleMeta {
    height: 20px;
    color: #707b87;
    font:
        bold 10px/20px Arial,
        Helvetica;
    letter-spacing: 0.2ex;
    text-transform: uppercase;
}
#main-content .articleItem .articleMeta a {
    color: #ff001e;
}
#main-content .articleItem .imageWrapper {
    float: left;
    margin-top: 10px;
    margin-right: 0;
    width: 220px;
    height: 140px;
}
#main-content .articleItem .imageWrapper a {
    display: block;
}
#main-content .articleItem .imageWrapper img {
    display: block;
    width: 220px;
    height: 140px;
}
#main-content .articleItem .articleContent {
    height: 139px;
    width: 390px;
    float: right;
    position: relative;
    margin-left: 10px;
    border-bottom: 1px #b6c1cc solid;
    padding-bottom: 7px;
    padding-top: 3px;
}
#main-content .articleItem .sharingButtons {
    float: left;
    margin: 7px 10px 5px 0;
}
#main-content .articleItem .sharingButtons.vertical a {
    height: 37px;
    padding-top: 0;
}
#main-content .articleItem .sharingButtons.vertical .facebook .icon {
    margin-top: 9px;
    /* total height 18px */
}
#main-content .articleItem .sharingButtons.vertical .twitter .icon {
    margin-top: 11px;
    /* total height 16px */
}
#main-content .articleItem .sharingButtons.vertical .comments .icon {
    margin-top: 12px;
    /* total height 13px */
}
#main-content .articleItem .sharingButtons.vertical small {
    display: block;
    padding-top: 4px;
    position: static;
    bottom: auto;
    left: auto;
}
#main-content .articleItem h3 {
    margin: 0;
    height: 104px;
    font:
        bold 24px/26px Arial,
        Helvetica;
    overflow: hidden;
}
#main-content .articleItem a {
    color: #343f4b;
    text-decoration: none;
}
#main-content .articleItem a:hover,
#main-content .articleItem a:focus,
#main-content .articleItem a.readMore:hover,
#main-content .articleItem a.readMore:focus {
    color: #dc0032;
    text-decoration: underline;
}
#main-content .articleItem a.readMore {
    font:
        bold 10px/15px Arial,
        Helvetica;
    color: #98a3af;
}
#main-content .articleItem .promoted.label {
    position: absolute;
    left: -10px;
    top: 10px;
    display: block;
    height: 30px;
}
#main-content .articleItem .promoted.label a {
    position: relative;
    display: block;
    height: 30px;
    padding: 0 20px 0 10px;
    color: #fff;
    font:
        bold 10px/30px Arial,
        Helvetica;
    letter-spacing: 0.2ex;
    text-transform: uppercase;
    background: transparent url(../../../images/ahacz/label-red.png) no-repeat right top;
    z-index: 1;
}
#main-content .articleItem .promoted.label .ribbon {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat 0 0;
}
#main-content .articleItem:hover h3 a {
    color: #dc0032;
}
#main-content .articleItemSmall {
    margin-bottom: 10px;
    position: relative;
}
#main-content .articleItemSmall a {
    text-decoration: none;
}
#main-content .articleItemSmall a h4 {
    margin: 0;
    padding: 3px 10px 3px 0;
    height: 64px;
    font:
        bold 14px/16px Arial,
        Helvetica;
    color: #343f4b;
    background: #fff;
    -moz-transition: all 0.05s ease-out;
    -o-transition: all 0.05s ease-out;
    -webkit-transition: all 0.05s ease-out;
    -ms-transition: all 0.05s ease-out;
    transition: all 0.05s ease-out;
}
#main-content .articleItemSmall a .imageWrapper {
    float: left;
    margin-right: 10px;
    width: 125px;
    height: 70px;
}
#main-content .articleItemSmall a .imageWrapper img {
    width: 110px;
    height: 70px;
}
#main-content .articleItemSmall a:hover h4,
#main-content .articleItemSmall a:focus h4 {
    color: #fff;
    background: #ff001e;
}
#main-content .articleItem.first,
#main-content .articleItemSmall.first {
    margin-top: 0;
}
#main-content .onlineTalk .sectionHeading {
    margin: 20px 0 0 0;
}
#main-content .onlineTalk .sectionHeading div {
    background-color: #5c6773;
}
#main-content .onlineTalk dl dt,
#main-content .onlineTalk dl dd {
    padding: 15px 0;
}
#main-content .onlineTalk dl dt {
    font-weight: bold;
}
#main-content .onlineTalk dl dd {
    margin: 0;
    border-bottom: 1px #cad5df solid;
}
#main-content .onlineTalk dl dd > img {
    max-width: 80px;
    float: left;
    padding: 2px;
    background-color: white;
    border: 1px #b6c1cc solid;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
#main-content .onlineTalk dl dd > div {
    position: relative;
    overflow: hidden;
    padding-left: 20px;
    color: #ff001e;
}
#main-content .onlineTalk dl dd > div span.arrow {
    float: left;
    display: block;
    margin-left: -13px;
    margin-right: 5px;
    margin-top: 9px;
    width: 8px;
    height: 8px;
    background: transparent url(../../../images/ahacz/elements.png?v=12) no-repeat -213px -50px;
}
#main-content .onlineTalk dl span.meta {
    margin-left: 1em;
    font:
        bold 12px/25px Arial,
        Helvetica;
}
#main-content .onlineTalk input.text,
#main-content .onlineTalk textarea {
    padding-left: 10px;
    padding-right: 10px;
    width: 440px;
}
#main-content .onlineTalk textarea {
    height: 125px;
}
#main-content .onlineTalk input.button,
#main-content .onlineTalk a.button {
    display: block;
    width: 160px;
    margin: 15px auto;
}
#main-content .onlineTalk a.button {
    height: 28px;
}
#main-content .articleLink .sharingButtons.vertical {
    margin-top: 10px;
}
#main-content .articleLink .sharingButtons.vertical .fb-like-container {
    background-color: #fff;
}
#main-content .articleLink .imageWrapper {
    margin-right: 10px;
}

#article .adContainer {
    margin: 30px 0 30px -10px;
}
#article img,
#article embed {
    max-width: 100%;
}
#article .fbLikeUs a {
    display: block;
    width: 460px;
    height: 47px;
    margin: 15px 0;
    background: transparent url(../../../images/ahacz/fb-artlink.png) no-repeat 0 0;
}

#article .fbLikeUs a:hover,
#article .fbLikeUs a:focus,
#article .fbLikeUs a:active {
    background-position: 0 -47px;
}
#article .fbLikePanicci a {
    display: block;
    width: 460px;
    height: 70px;
    margin: 15px 0;
    background: transparent url(../../../images/ahacz/banner-facebook-3.png) no-repeat 0 0;
}

.articleItemMed.withIcon .iconC {
    display: block;
    float: right;
}

.articleItem.withIcon .iconC {
    display: block;
    position: absolute;
}

.articleItemMed.withIcon .iconC {
    width: 30px;
    height: 30px;
}

.articleItem.withIcon .iconC {
    width: 30px;
    height: 30px;
    left: 190px;
    bottom: 0px;
}

.articleItemMed.withIcon .iconC,
.articleItem.withIcon .iconC {
    background: transparent url("../../../images/ahacz/elements.png?v=12") no-repeat;
}

.articleItemMed.withIcon .iconC.iconPhoto {
    background-position: -101px -111px;
}

.articleItemMed.withIcon .iconC.iconVideo {
    background-position: -133px -111px;
}

.articleItem.withIcon .iconC.iconPhoto {
    background-position: -101px -111px;
}

.articleItem.withIcon .iconC.iconVideo {
    background-position: -132px -111px;
}

#mainFooter {
    float: none;
}
#mainFooter #bottomStatements {
    padding: 25px 0 30px 0;
}
#mainFooter #bottomStatements p {
    font:
        12px/30px Arial,
        Helvetica;
    color: #707b87;
}
#mainFooter #bottomStatements p a {
    color: #707b87;
    text-decoration: none;
}
#mainFooter #bottomStatements p a:hover,
#mainFooter #bottomStatements p a:focus {
    text-decoration: underline;
}

/* Article */
#article h1 {
    margin-bottom: 10px;
    line-height: 40px;
}

#article .articlePhoto {
    width: 470px;
    min-height: 70px;
    margin-left: -10px;
    background: #fff;
}

#article .articlePhoto img {
    width: 470px;
}

#article .articlePhoto .description {
    padding: 10px 10px 20px;
    font-size: 14px;
    font-weight: bold;
}

#article .articlePhoto .description .source {
    color: #98a3af;
    font-weight: normal;
}

#article .articlePhoto .galleryContent {
    margin-left: -151px;
    background: transparent;
    background-color: #fff;
    height: 70px;
    overflow: hidden;
    width: 620px;
}

#article .articlePhoto .galleryContent .inner_2.gallery {
    background: white;
    width: 130px;
    margin-right: 1px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0px;
}
#article .articlePhoto .galleryContent .inner_2.gallery p {
    color: #98a3af;
    text-transform: none;
}

#article .articlePhoto .galleryContent .inner_2.gallery a {
    color: #ff001e;
    text-decoration: none;
}

#article .articlePhoto .galleryContent .inner_2.gallery a:hover {
    text-decoration: underline;
}

#article .articlePhoto .galleryContent .carouselContainer {
    position: relative;
}

#article .articlePhoto .galleryContent .carouselList {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    top: 0px;
}

#article .articlePhoto .galleryContent .grid_6.carousel,
#article .articlePhoto .galleryContent .carouselContainer {
    width: 469px;
}

#article .articlePhoto .galleryContent .carousel .carouselItem {
    float: left;
    margin-right: 10px;
    height: 70px;
    width: 110px;
    height: 70px;
}

#article .articlePhoto .galleryContent .carousel .carouselItem img,
#article .articlePhoto .galleryContent .carousel .carouselItem a img {
    display: block;
    width: 110px;
    height: 70px;
}

#article .galleryContent .buttonPrev,
#article .galleryContent .buttonNext {
    width: 29px;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    height: 70px;
    background-color: #ff001e;
    background-position: center center;
    background-repeat: no-repeat;
}

#article .galleryContent .buttonPrev {
    left: 0px;
    border-right: 1px solid #4c545d;
    background-image: url(../../../images/ahacz/prev-white.png);
}

#article .galleryContent .buttonNext {
    right: 0px;
    border-left: 1px solid #4c545d;
    background-image: url(../../../images/ahacz/next-white.png);
}

#article .galleryContent .buttonPrev:hover,
#article .galleryContent .buttonNext:hover,
#article .galleryContent .buttonPrev:focus,
#article .galleryContent .buttonNext:focus {
    background-color: #dc0032;
}

.buttonNextDisabled,
.buttonPrevDisabled {
    display: none;
}

#article .galleryContent .header {
    color: #ff001e;
}

#article .carouselClip {
    position: relative;
    width: 470px;
    height: 70px;
    overflow: hidden;
    top: 0;
    left: 0;
}

/* sedy nadpis */
#main-content .grey .articles h2 a,
#main-content .grey .asideArticles h3 a {
    background-color: #cad5df;
    color: #707b87;
}

#main-content .grey .articles h2 a:hover,
#main-content .grey .asideArticles h3 a:focus,
#main-content .grey .articles h2 a:hover,
#main-content .grey .asideArticles h3 a:focus {
    background-color: #b6c1cc;
}

/* social */
#article .inner_2.sharingButtons {
    margin-left: -160px;
    padding: 0 10px 10px 10px;
}

#article .sharingButtons .fb {
    margin-bottom: 10px;
}

#article .sharingButtons .discusion .icon.comments {
    float: left;
}

#article .sharingButtons .discusion small {
    position: relative;
    top: 6px;
    left: 5px;
}

#article .body,
#article p {
    font-size: 16px;
    line-height: 25px;
    color: #343f4b;
}
#article p {
    margin: 10px 0px;
}

.perex {
    color: #343f4b;
    font-weight: bold;
    margin-top: 20px;
}

#article .articleMeta {
    color: #707b87;
    font-size: 12px;
}

#article .articleMeta .category a {
    color: #ff001e;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
}

#article .articleMeta .category a:hover {
    text-decoration: underline;
}

/* keywords footer
--------------------------------------------------------------------------------*/
.keywordsFooter {
    clear: both;
    color: #7b858b;
    font-size: 12px;
    line-height: 18px;
    float: none;
}

.keywordsFooter a {
    color: #7b858b;
    text-decoration: none;
}

.keywordsFooter a:hover {
    color: #cd0032;
}

.landingPage {
    color: #7b858b;
    font-size: 12px;
    line-height: 18px;
}

.footer ul li {
    line-height: 16px !important;
}

/** diskuse **/
.forum {
    margin: 0 0 25px;
}

.forum .bottom {
    border-bottom: 1px solid #cad5df;
    padding: 10px 0 10px;
}

.forum .bottom .buttons {
    margin: 0 0 0 35%;
}

.forum .buttons a {
    display: block;
    font-weight: bold;
    color: #343f4b;
    font-size: 12px;
    text-decoration: none;
}

.forum .buttons a.btnRight {
    float: right;
}

.forum .buttons a.btnLeft {
    float: left;
}

.forum .bottom .buttons a:hover {
    text-decoration: underline;
}

.forum .message {
    width: 100%;
    border-bottom: 1px solid #cad5df;
    padding: 10px 0 20px;
}

.forum .message .info {
    width: 35%;
    float: left;
    color: #98a3af;
    font-size: 12px;
    line-height: 20px;
}

.forum .message .info .nickname {
    color: #343f4b;
    font-size: 14px;
}

.forum .message .text {
    width: 65%;
    float: left;
}

.forum .message .text p {
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
}

/* obecna definice side-boxu na homepage ahacz */
.box {
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.box.noMargin {
    margin-bottom: 0;
}

.box a {
    text-decoration: none;
}

.box h3 {
    color: white;
    background-color: #98a3af;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-indent: 10px;
    text-transform: uppercase;
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
}

.boxLeft h3 {
    margin-left: -10px;
}

.boxRight h3 {
    margin-right: -10px;
}

.box h3 a {
    display: block;
    color: white;
}

.box h3 a span {
    display: block;
    width: 12px;
    height: 12px;
    float: right;
    margin-top: 4px;
    margin-right: 7px;
    background: url("../../../images/ahacz/elements.png?v=12") -210px -60px no-repeat transparent;
}

.box h3 a:hover {
    background-color: #343f4b;
}

.boxLeft h3 a {
    padding-left: 10px;
}

.boxLeft {
    padding-left: 10px;
    margin-right: 10px;
    background: url("../../../images/ahacz/ribbon-left-midgrey.png") left 20px no-repeat;
}

.boxRight {
    margin-right: -10px;
    padding-right: 10px;
    background: url("../../../images/ahacz/ribbon-right-midgrey.png") right 20px no-repeat;
}

/* box pro horoskokp - leva strana */
.boxHoroskop #sign {
    background: white;
    padding-left: 10px;
    padding-right: 4px;
    padding-bottom: 7px;
    font-size: 14px;
    color: #343f4b;
}

.boxHoroskop h4 {
    font-weight: bold;
    height: 33px;
    line-height: 33px;
    margin: 0;
    padding: 7px 0 0;
}

.boxHoroskop h4 span {
    display: block;
    float: right;
    background: url("../../../images/ahacz/elements.png?v=12") no-repeat transparent;
    width: 33px;
    height: 33px;
    margin-right: 6px;
    margin-top: -3px;
}

.boxHoroskop .pisces h4 span {
    background-position: -165px 0px;
}

.boxHoroskop .aquarius h4 span {
    background-position: -165px -33px;
}

.boxHoroskop .capricorn h4 span {
    background-position: -165px -66px;
}

.boxHoroskop .sagittarius h4 span {
    background-position: -165px -99px;
}

.boxHoroskop .scorpio h4 span {
    background-position: -165px -132px;
}

.boxHoroskop .libra h4 span {
    background-position: -165px -165px;
}

.boxHoroskop .virgo h4 span {
    background-position: -165px -198px;
}

.boxHoroskop .leo h4 span {
    background-position: -165px -231px;
}

.boxHoroskop .cancer h4 span {
    background-position: -165px -264px;
}

.boxHoroskop .gemini h4 span {
    background-position: -165px -297px;
}

.boxHoroskop .taurus h4 span {
    background-position: -165px -330px;
}

.boxHoroskop .aries h4 span {
    background-position: -165px -363px;
}

.boxHoroskop p {
    line-height: 18px;
    height: 129px;
    overflow: hidden;
}

.boxHoroskop #sign a {
    color: #343f4b;
}
.boxHoroskop #sign a:hover {
    color: #ff001e;
}

.boxHoroskop a.more {
    display: block;
    margin-top: 5px;
    font-size: 9px;
    line-height: 12px;
    color: #707b87;
    font-weight: bold;
    text-transform: uppercase;
}

.boxHoroskop a.more span {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -48px no-repeat transparent;
    display: block;
    float: left;
    width: 12px;
    height: 12px;
    margin-left: -4px;
    margin-right: 1px;
}

/* box pro predpoved pocasi - prava strana */
.boxPocasi ul {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #343f4b;
    padding: 10px;
}

.boxPocasi li {
    display: block;
    color: white;
}

.boxPocasi li.today {
    margin-top: 15px;
}

.boxPocasi li strong,
.boxPocasi li span {
    display: block;
}

.boxPocasi li strong {
    font-size: 23px;
}

.boxPocasi li span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 10px;
    padding-left: 54px;
    margin-left: 6px;
    background: url("../../../images/ahacz/weather-icons.png") left top no-repeat;
}

/* TODO: ikonky je treba predelat, az dojdou od grafika */
.boxPocasi li span.jasno {
    background-position: 0 -40px;
}

.boxPocasi li span.polojasno {
    background-position: 0 -200px;
}

.boxPocasi li span.polojasno-dest {
    background-position: 0 -240px;
}

.boxPocasi li span.mlha {
    background-position: 0 -80px;
}

.boxPocasi li span.mlha-dest {
    background-position: 0 -240px;
}

.boxPocasi li span.mlha-snih {
    background-position: 0 -280px;
}

.boxPocasi li span.oblacno {
    background-position: 0 -80px;
}

.boxPocasi li span.oblacno-bourka {
    background-position: 0 -160px;
}

.boxPocasi li span.oblacno-dest {
    background-position: 0 -240px;
}

.boxPocasi li span.oblacno-dests {
    background-position: 0 -280px;
}

.boxPocasi li span.oblacno-snih {
    background-position: 0 -280px;
}

.boxPocasi li span.zatazeno {
    background-position: 0 -80px;
}

.boxPocasi li span.zatazeno-bourka {
    background-position: 0 -160px;
}

.boxPocasi li span.zatazeno-dest {
    background-position: 0 -240px;
}

.boxPocasi li span.zatazeno-dests {
    background-position: 0 -280px;
}

.boxPocasi li span.zatazeno-snih {
    background-position: 0 -280px;
}

.boxPocasi li p {
    text-transform: lowercase;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #707b87;
    font-size: 12px;
    line-height: 14px;
    height: 40px;
    /* 4 lines */
    overflow: hidden;
}

.boxPocasi li p:first-letter {
    text-transform: uppercase;
}

/* box pro TV program - prava strana */
.boxTvprogram .channel {
    background-color: white;
    display: none;
    padding: 15px 5px 10px;
}

/* zde je id kanalu, ktery se ma zobrazit jako prvni: */
.boxTvprogram .channel#ch_3 {
    display: block;
}

.boxTvprogram .channel span {
    display: none;
}

.boxTvprogram span.from,
.boxTvprogram span.progressbar,
.boxTvprogram span.progress,
.boxTvprogram span.to {
    display: block;
    float: left;
}

.boxTvprogram .channelHeading {
    margin-bottom: 15px;
}

.boxTvprogram .channelHeading h4 {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    overflow: hidden;
    width: 80px;
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.boxTvprogram .channelHeading a.prev,
.boxTvprogram .channelHeading a.next {
    float: left;
    height: 20px;
    width: 20px;
}

.boxTvprogram .channelHeading a.prev span.prev,
.boxTvprogram .channelHeading a.next span.next {
    display: block;
    background: url("../../../images/ahacz/elements.png?v=12") no-repeat transparent;
    margin-left: 4px;
    margin-top: 4px;
    width: 12px;
    height: 12px;
}

.boxTvprogram .channelHeading a.prev span.prev {
    background-position: -198px 0;
}

.boxTvprogram .channelHeading a.next span.next {
    background-position: -210px 0px;
    float: right;
}

.boxTvprogram .channelContent {
    min-height: 128px;
    /* 7 lines + 9px margin between title and text */
    max-height: 128px;
    overflow: hidden;
}

.boxTvprogram .channelPreview {
    text-align: center;
    margin-bottom: 10px;
}

.boxTvprogram p {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 9px;
}

.boxTvprogram p strong {
    font-weight: bold;
}

.boxTvprogram .time {
    padding-top: 10px;
}

.boxTvprogram .time span {
    font-size: 14px;
}

.boxTvprogram .time .from,
.boxTvprogram .time .to {
    width: 45px;
}

.boxTvprogram .time .to {
    float: right;
    text-align: right;
}

.boxTvprogram .progressbar {
    height: 10px;
    width: 40px;
    margin-top: 3px;
    background-color: #cad5df;
    position: relative;
}

.boxTvprogram .progress {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    background-color: #343f4b;
}

.boxTvprogram .programLink {
    display: block;
    color: #343f4b;
    padding: 7px;
    background: white;
}
.boxTvprogram h4 {
    font-weight: bold;
    height: 33px;
    margin: 0;
    padding: 0 30px 0 0;
    background: transparent url(../../../images/ahacz/ico-tv.png) no-repeat right top;
}
.boxTvprogram span {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -47px no-repeat transparent;
    display: inline-block;
    width: 12px;
    height: 12px;
}
.boxTvprogram .programLink:hover,
.boxTvprogram .programLink:focus {
    color: #ff001e;
}

/* **************************************************************** */
/* FORUM BOX ARTICLE */
.pagination {
    margin: 10px 0;
}

.pagination .pages {
    text-align: center;
}

.pagination .page {
    padding: 5px 10px;
    font-weight: normal;
}

.pagination .currentPage {
    font-weight: bold;
}

.pagination .prevPage,
.pagination .nextPage {
    display: inline-block;
}

.pagination .prevPage span,
.pagination .nextPage span {
    display: block;
    margin-top: 3px;
    height: 12px;
    width: 12px;
    background-image: url("../../../images/ahacz/elements.png?v=12");
    background-repeat: no-repeat;
}

.pagination .prevPage span {
    float: left;
    margin-right: 2px;
    background-position: -198px -48px;
}

.pagination .nextPage span {
    float: right;
    margin-left: 2px;
    background-position: -210px -48px;
}

.linkLine {
    background: url("../../../images/ahacz/link-line.png") center center no-repeat;
    text-align: center;
    margin: 20px 0;
}

.linkLine a {
    display: inline-block;
    padding: 0 40px;
    line-height: 20px;
    height: 20px;
    color: #343f4b;
    text-transform: uppercase;
    background: url("../../../images/ahacz/link-item-left.png") 20px center no-repeat white;
    font-size: 9px;
    font-weight: bold;
}

.forumBox {
    float: right;
}

.forum p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}

textarea {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #cad5df;
    border-radius: 1px 1px 1px 1px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
    color: #98a3af;
    font-size: 15px;
    height: 28px;
    padding: 5px;
    outline: none;
}

.forum input.submit {
    padding: 0 40px;
}

.forum {
    font-size: 14px;
}

.forum ul {
    display: block;
    margin: 0;
    padding: 0;
}

.forum a {
    text-decoration: none;
    font-weight: bold;
    color: #5c6773;
}

.forum a:hover {
    text-decoration: underline;
}

.forum input::-webkit-input-placeholder,
.forum textarea::-webkit-input-placeholder {
    color: #98a3af;
}

.forum input:-moz-placeholder,
.forum textarea:-moz-placeholder {
    color: #98a3af;
}

.forum input::-moz-placeholder,
.forum textarea::-moz-placeholder {
    color: #98a3af;
}

.forum input:-ms-input-placeholder,
.forum textarea:-ms-input-placeholder {
    color: #98a3af;
}

span.bulletPlus,
span.bulletArrow {
    display: block;
    float: left;
    background-image: url("../../../images/ahacz/elements.png?v=12");
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-top: 1px;
}

span.bulletPlus {
    background-position: -232px -35px;
}

span.bulletArrow {
    background-position: -210px -35px;
}

#main-content .forumHeader .readMore {
    font-size: 14px;
}
#main-content .forumHeader .articlePerex {
    margin-bottom: 0;
}

#badwordsCont {
    display: none;
}

.forum .artForumInfo {
    padding: 10px;
}
.forum .artForumInfo a {
    color: #ff001e;
}

.forum .commentsBox,
.forum .pagination {
    clear: both;
}

.forum .pagination {
    margin-top: 20px;
}

.forum .myComments {
    margin-left: 0;
}

.forum .forumTop {
    min-height: 316px;
    padding-bottom: 20px;
}

.forum header,
.forum h2 {
    margin-bottom: 10px;
}

.forum .message.last {
    border-bottom: none;
}

.forum .message.deleted {
    color: #98a3af;
}

.forum .buttons a.btnLeft {
    margin-right: 10px;
}

.forum .buttons a.btnRight {
    margin-left: 10px;
}

.forum .forumBoxInner a.red {
    color: #ff001e;
}

.forum .forumBoxInner a.red:hover,
.forum .forumBoxInner a.red:focus {
    color: #dc0032;
}

.forum .headerGray {
    margin-left: -20px;
    padding-bottom: 10px;
    background: url("../../../images/ahacz/ribbon-left-midgrey.png") no-repeat scroll left bottom transparent;
    position: relative;
}

.forum .headerGray div {
    background-color: #cad5df;
    display: block;
    color: #707b87;
    line-height: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
    padding-left: 20px;
}

.forum header h1 {
    line-height: 40px;
}

.forum header strong.threadDesc {
    font-weight: bold;
    font-size: 14px;
}

.forum .loggedBox .banned {
    background-color: #cc0018;
    color: white;
    padding: 5px 10px;
}

.forum .loggedBox,
.forum .loginBox,
.forum .loggedBox ul,
.forum .loginBox ul {
    margin-left: -10px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.forum .loggedBox,
.forum .loginBox {
    padding-top: 10px;
    border-top: 1px solid #cad5df;
}

.forum .loggedBox ul,
.forum .loginBox ul {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cad5df;
    padding-top: 5px;
}

.forum .loginBox li,
.forum .loggedBox li {
    display: inline-block;
    list-style-type: none;
    padding-right: 10px;
}

.forum .loginBox li span,
.forum .loggedBox li span {
    display: inline-block;
    float: left;
    background: url("../../../images/ahacz/elements.png?v=12") -210px -48px no-repeat transparent;
    width: 12px;
    height: 12px;
}

.forum .loginBox li a,
.forum .loggedBox li a {
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    display: inline-block;
}

.forum .loginForm .input,
.forum .loginForm input.submit,
.forum .loginForm div.terms {
    margin-bottom: 10px;
}

.forum .loginForm .input label,
.forum .loginForm .input input {
    display: block;
    float: left;
}

.forum .loginForm .input label {
    padding-right: 10px;
    text-align: right;
    width: 90px;
}

.forum .loginForm .input input {
    width: 245px;
}

.forum .loginForm input.submit,
.forum .loginForm div.terms {
    margin-left: 100px;
}

.forum .replyForm .smileys a {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #cad5df;
    border-radius: 1px 1px 1px 1px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
}

.forum .replyForm .smileys img {
    display: block;
}

.forum .replyForm input.subject,
.forum .replyForm textarea {
    display: block;
    width: 448px;
    margin-bottom: 10px;
}

.forum .replyForm textarea {
    min-height: 100px;
    resize: vertical;
}

.forum .replyForm .remaining {
    float: right;
    font-size: 12px;
    line-height: 24px;
    color: #707b87;
}

.forum .replyForm .smileys a {
    display: block;
    float: left;
    text-decoration: none;
    margin-bottom: 2px;
    background: white;
    margin-right: 2px;
    padding: 2px;
}

.forum .replyForm .notify {
    line-height: 30px;
    font-size: 14px;
    float: right;
}

.forum .replyForm .notify label {
    line-height: inherit;
}

.forum .replyForm .notify input {
    vertical-align: middle;
}

.forum #mainReact,
.forum #mainLogin {
    display: none;
    padding-bottom: 10px;
    font-size: 12px;
}

.forum .commentsBox h4 {
    margin-top: 0;
}

.forum .commentsBox .message p {
    margin-bottom: 0;
}

.forum .commentsBox .level0 {
    margin-left: 0px;
}

.forum .commentsBox .level1,
.forum .commentsBox .replyLevel1 {
    margin-left: 40px;
}

.forum .commentsBox .level2,
.forum .commentsBox .replyLevel2 {
    margin-left: 80px;
}

.forum .commentsBox .level3,
.forum .commentsBox .replyLevel3,
.forum .commentsBox .replyLevel4 {
    margin-left: 120px;
}

.forum .commentsBox .replyForm textarea {
    min-height: 100px;
}

.forum .commentsBox .replyLevel1 textarea {
    width: 99%;
}

.forum .commentsBox .replyLevel2 textarea {
    width: 99%;
}

.forum .commentsBox .replyLevel3 textarea,
.forum .commentsBox .replyLevel4 textarea {
    width: 99%;
}

.forum .commentsBox .loginForm form,
.forum .commentsBox .replyForm form {
    border-bottom: 1px solid #cad5df;
    padding: 10px 0;
}

.forum .commentsBox .loginForm form {
    padding-left: 35%;
}

/* **************************************************************************** */
/* FOOTER CONTACTS */
.footerContacts {
    font-size: 14px;
}

.footerContacts h3 {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: #5c6773;
    padding-bottom: 10px;
    letter-spacing: -1px;
}

.footerContacts a {
    text-decoration: none;
}

.footerContacts a:hover {
    text-decoration: underline;
}

.footerContacts ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    width: 320px;
    float: left;
}

.footerContacts ul.links {
    width: 300px;
}

.footerContacts li {
    list-style-type: none;
    width: 320px;
    float: left;
    font-weight: bold;
    line-height: 26px;
}

.footerContacts li strong {
    width: 140px;
    display: inline-block;
    font-weight: normal;
}

.footerContacts ul.links li strong {
    width: 80px;
}

.footerContacts li strong span {
    display: none;
}

.footerContacts p {
    width: 320px;
    float: right;
    line-height: 20px;
}

/* ************************************************************************************* */
/* LINK-DEFAULT */
#main-content .articleLink {
    background: white;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

#main-content .articleLink .articleMeta {
    display: block;
    height: auto;
    padding-bottom: 5px;
}

#main-content .articleLink .articleContent {
    border-bottom: none;
    width: 330px;
    padding-right: 10px;
}

.relArts {
    margin-left: -170px;
    margin-right: -10px;
}

/* ******************************************************************************** */
/* ANKETA */
#main-content .enquiry {
    background: white;
}

#main-content .enquiry .enquiryInner {
    background-color: white;
    position: relative;
}

#main-content .enquiry h2 {
    /* #993, 1037 */
    background: url("../../../images/ahacz/ribbon-left-grey.png") no-repeat scroll left bottom transparent;
    padding-bottom: 10px;
    margin-left: -10px;
}

#main-content .enquiry h2 span {
    display: block;
    background-color: #ff001e;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-weight: bold;
    letter-spacing: -1px;
    padding-left: 20px;
}

#main-content .enquiry .question strong {
    font-size: 24px;
    font-weight: bold;
    height: 100px;
}

#main-content .enquiry .question span {
    color: #707b87;
    font-size: 12px;
}

#main-content .enquiry ul {
    margin: 0;
    padding: 0;
}

#main-content .enquiry li {
    list-style-type: none;
    display: block;
    height: 40px;
    margin-bottom: 5px;
}

#main-content .enquiry li .answer,
#main-content .enquiry li .value {
    line-height: 15px; /* 36px;*/
}

#main-content .enquiry li a {
    display: block;
    position: relative;
    font-size: 16px;
    color: #5c6773;
    text-decoration: none;
}

#main-content .enquiry.voted li .answer {
    cursor: default;
}

#main-content .enquiry li .value {
    display: block;
    float: right;
    font-weight: bold;
    padding-right: 10px;
}
#main-content .enquiry li .graph {
    display: block;
    clear: both;
    background-color: #cad5df;
    height: 5px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
}
#main-content .enquiry li .graphValue {
    display: block;
    position: absolute;
    background-color: #ff001e;
    height: 5px;
    left: 0;
    top: 0;
}

#main-content .enquiry li a:hover .answer,
#main-content .enquiry li a:focus .answer {
    text-decoration: underline;
    color: #343f4b;
}
#main-content .enquiry li a:hover .value,
#main-content .enquiry li a:focus .value {
    color: #343f4b;
}
#main-content .enquiry li a:hover .graph,
#main-content .enquiry li a:focus .graph {
    background-color: #b6c1cc;
}
#main-content .enquiry li a:hover .graphValue,
#main-content .enquiry li a:focus .graphValue {
    background-color: #dc0032;
}

#main-content .enquiry.voted li a:hover .answer {
    text-decoration: none;
}

.csstransitions #main-content .enquiry li a .answer {
    text-decoration: none;
    display: inline-block;
    width: 325px;
}
.csstransitions #main-content .enquiry li a .graph,
.csstransitions #main-content .enquiry li a .graphValue {
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -webkit-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
.csstransitions #main-content .enquiry li a:hover .graph,
.csstransitions #main-content .enquiry li a:focus .graph {
    height: 8px;
}
.csstransitions #main-content .enquiry li a:hover .graphValue,
.csstransitions #main-content .enquiry li a:focus .graphValue {
    height: 8px;
}

#main-content .enquiry .countVotes {
    color: #707b87;
    font-size: 12px;
    padding-left: 10px;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
/* Image replacement */
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
}

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden {
    display: none !important;
    visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* Extends the .visuallyhidden class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*Hide visually and from screenreaders, but maintain layout */
.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    clear: both;
}

.clear {
    clear: both;
}

/*For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats. */
.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.alignLeft {
    float: left;
}

.alignRight {
    text-align: right;
}

.centerText {
    text-align: center;
}

.centerBlock {
    margin-left: auto;
    margin-right: auto;
}

.imageWrapper {
    overflow: hidden;
    position: relative;
    display: block;
}

.positionRelative,
.img {
    position: relative;
}

.borderTop,
.bTop {
    border-top: 5px #ff001e solid;
    padding-top: 10px;
}

.borderBottom,
.bBottom {
    border-bottom: 5px #ff001e solid;
    padding-bottom: 10px;
}

.readMore {
    display: inline-block;
    text-transform: uppercase;
    color: #98a3af;
    text-decoration: none;
}

.readMore:hover {
    text-decoration: underline;
}

.readMore span {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -47px no-repeat transparent;
    display: block;
    float: left;
    width: 12px;
    height: 12px;
    margin-top: 1px;
    margin-left: -4px;
    margin-right: 1px;
}

.marginTop,
.mTop {
    margin-top: 30px;
}

.marginBottom,
.mBottom {
    margin-bottom: 30px;
}

.marginTop20,
.mTop20 {
    margin-top: 20px;
}

.marginBottom20,
.mBottom20 {
    margin-bottom: 20px;
}

.marginTop10,
.mTop10 {
    margin-top: 10px;
}

.marginBottom10,
.mBottom10 {
    margin-bottom: 10px;
}

.preventOverflow img,
.preventOverflow iframe,
.preventOverflow embed {
    max-width: 100%;
}

.marginTop-12,
.mTop-12 {
    margin-top: -12px;
}

.icon {
    display: block;
}

.red {
    color: #ff001e;
}

.fixed {
    position: fixed;
    top: 0;
}

.pullLeft10 {
    margin-left: -10px;
}

.loader {
    width: 16px;
    height: 16px;
    background: transparent url(../../../images/ahacz/loader.gif) no-repeat center center;
}

.csstransitions .transition {
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.csstransitions .transition10 {
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.csstransitions .transition50 {
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.fb-title {
    background: red;
    color: #fff;
    display: block;
    width: 100%;
    padding-left: 10px;
}

/* extended content in detail article
---------------------------------------*/
.recentGalleriesAdd .recentGalleries {
    display: block;
}
.articlePhoto.articlePhotoHidden {
    display: none;
}

#forum-top-box #forum-title {
    background: #ff001e;
    display: block;
    height: 40px;
    width: 100%;
    padding-left: 10px;
    background-color: #ff001e;
    color: #fff;
    text-decoration: none;
}

/*box hledamepanicky na hp*/
#hledamePanicky {
    position: relative;
    z-index: 100;
}
#hledamePanicky .stopaPsa {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 43px !important;
    height: 39px !important;
    pointer-events: none;
    background: transparent url(../../../images/ahacz/stopa_psa.png) no-repeat center center !important;
}
#hledamePanicky .arrow {
    float: right;
}
#hledamePanicky .articleItemSmall a {
    position: relative;
}
#hledamePanicky .articleItemSmall a .imageWrapper {
    width: 300px;
    height: auto;
    float: none;
}
#hledamePanicky .articleItemSmall a .imageWrapper img {
    width: 100%;
    height: 100%;
}
#hledamePanicky .articleItemSmall a h4 {
    padding: 10px;
    height: 15px;
}

.videoHP .blackBox {
    background-color: #343f4b;
    padding-top: 10px;
}
#main-content .videoHP .blackBox .articleItemSmall {
    display: block;
    float: left;
    width: 195px;
}
#main-content .videoHP .blackBox .articleItemSmall:nth-child(2) {
    margin: 0 18px 0 17px;
}

#main-content .videoHP .blackBox .articleItemSmall .icon-play {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 79px;
    width: 38px;
    height: 37px;
    margin: 0 auto;
    background: url("../../../images/ahacz/elements.png?v=12") -98px -151px no-repeat transparent;
}
#main-content .videoHP .blackBox .imageWrapper {
    width: 195px;
    height: auto;
    padding-bottom: 5px;
}
#main-content .videoHP .blackBox .imageWrapper img {
    width: 195px;
    height: 124px;
}

#main-content .videoHP .blackBox .articleItemSmall h4 {
    background-color: transparent;
    color: white;
    margin: 0 auto;
    padding: 5px 8px 0 15px;
    height: auto;
}
#main-content .videoHP .blackBox .articleItemSmall a:hover h4 {
    background-color: transparent;
    text-decoration: underline;
}

#cat-5139 a:hover,
#cat-5139 a:active,
#cat-5139 a:focus,
.menu-logo a:hover,
.menu-logo a:active,
.menu-logo a:focus {
    -webkit-box-shadow: inset 0px 0 0px 0px rgba(0, 0, 0, 0) !important;
    -moz-box-shadow: inset 0px 0 0px 0px rgba(0, 0, 0, 0) !important;
    box-shadow: inset 0px 0 0px 0px rgba(0, 0, 0, 0) !important;
}

#cat-5139,
.menu-logo {
    background: rgba(0, 0, 0, 0) !important;
}
#cat-5139 a,
.menu-logo a {
    display: block;
    width: 150px !important;
    height: 50px !important;
    background: transparent url(../../../images/ahacz/elements.png?v=12);
    background-position: 0px -431px !important;
}

#cat-5139 a:hover,
#cat-5139 a:active,
#cat-5139 a:focus,
.menu-logo a:hover,
.menu-logo a:active,
.menu-logo a:focus {
    background: transparent url(../../../images/ahacz/elements.png?v=12) !important;
    background-position: 0px -431px !important;
}

#predplatne-pro-zeny {
    display: none;
}

/* galerie pod top pokud je top video */
#article .articlePhoto .galleryContent.gallery-after-video {
    margin-left: 0px;
    height: 100px;
    width: 100%;
}

#article .articlePhoto .galleryContent.gallery-after-video .inner_2.gallery {
    float: none;
    width: 100%;
}

#article .articlePhoto .galleryContent.gallery-after-video .inner_2.gallery p,
#article .articlePhoto .galleryContent.gallery-after-video .inner_2.gallery .header {
    float: left;
    margin: 0 10px 10px 0;
    line-height: 1;
}

/* box s daty z Dama.cz */
#main-content .asideArticles.dama .articleItemSmall {
    border-top: 1px #b6c1cc solid;
    padding: 5px 10px !important;
    margin-bottom: 0;
}

#main-content .asideArticles.dama .articleItemSmall.noBorder {
    border-top: 0px;
}

#main-content .asideArticles.dama .articleItemSmall a {
    font:
        bold 14px/15px Arial,
        Helvetica;
    color: #343f4b;
}

#main-content .asideArticles.dama .articleItemSmall a:hover {
    color: #dc0032;
}

#main-content .asideArticles.dama .articleItemSmall a span {
    color: #dc0032;
}

#main-content .articleItemSmall a .imageWrapper img {
    width: 124px;
    height: 83px;
}

/* box s daty z Maminka.cz */
#main-content .asideArticles.maminkacz .articleItemSmall a .imageWrapper {
    width: 120px;
    height: 68px;
}

/* box s daty - clanky odjinud */
#main-content .asideArticles.odjinud .articleItemSmall a .imageWrapper {
    width: 120px;
    height: 72px;
}

/******************************************************************************
 HP GALLERY
******************************************************************************/

.galleryHP {
    background: #343f4b;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}

.galleryHP .float-left {
    float: left;
    width: 150px;
}

.galleryHP .float-right {
    float: right;
    width: 790px;
}

.galleryHP .float-right .jcarousel-prev {
    display: none;
}

.galleryHP .float-right .jcarousel-next {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    background: #ff0000;
    text-indent: -9999px;
    cursor: pointer;
    width: 30px;
}

.galleryHP .float-right .jcarousel-next .arrow-next {
    background: url(../../../images/ahacz/elements.png?v=12) -210px -12px no-repeat transparent;
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 46%;
    left: 33%;
}

.galleryHP .title {
    color: #ff001e;
    margin: 5px 10px;
    font-size: 16px;
    line-height: 20px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

.galleryHP a.galleryLink {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    display: block;
    float: left;
    text-decoration: none;
    margin: 5px 10px;
    font-weight: bold;
}

.galleryHP a.galleryLink:hover {
    text-decoration: underline;
}

.galleryHP .jcarousel-clip {
    height: 90px;
    margin: 0px;
}
.galleryHP .jcarousel-list {
    height: 90px;
    overflow: hidden;
}
.galleryHP .jcarousel-list li {
    margin: 0px 10px;
    float: left;
}
.galleryHP .jcarousel-list li img {
    width: 140px;
    height: 90px;
}

.jcarousel {
    position: relative;
    overflow: hidden;
}

.jcarousel ul {
    width: 20000em;
    position: relative;
    /* Optional, required in this case since it's a <ul> element */
    list-style: none;
    margin: 0;
    padding: 0;
}

.jcarousel li {
    /* Required only for block elements like <li>'s */
    float: left;
}
/**************************************************
COOKIE BAR - desktop
***************************************************/
.cookie-bar {
    position: fixed;
    display: none;
    text-align: center;
    padding: 10px 0;
    font-family: "opensans-regular";
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(50, 50, 50, 0.95);
    width: 100%;
    z-index: 200000;
}
.cookie-bar a {
    color: inherit;
}

.cookie-bar__title {
    font-family: "opensans-bold";
    font-size: 26px;
    line-height: 36px;
}
.cookie-bar__title br {
    display: none;
}

.cookie-bar__text {
    font-size: 12px;
    line-height: 16px;
    padding: 5px 0 10px;
}
.cookie-bar__text br {
    display: inline;
}

.cookie-bar__enable-btn {
    display: inline-block;
    margin: 0 15px 0 auto;
    font-size: 14px;
    line-height: 35px;
    height: 35px;
    width: 140px;
    text-transform: Uppercase;
    background-color: #ff0000;
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: none;
}

.cookie-bar__more-info {
    font-size: 11px;
    margin-bottom: 0px;
    line-height: 15px;
    display: inline-block;
    text-decoration: underline;
}
.cookie-bar__more-info:hover {
    text-decoration: none;
}

.cookie-bar__close-btn {
    display: inline-block;
    width: 14px;
    height: 14px;
    overflow: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cookie-bar__close-btn::before,
.cookie-bar__close-btn::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
}
.cookie-bar__close-btn::before {
    transform: rotate(45deg);
}
.cookie-bar__close-btn::after {
    transform: rotate(-45deg);
}
.cookie-bar__close-btn--thick::before,
.cookie-bar__close-btn--thick::after {
    height: 3px;
    margin-top: -2px;
}

/******************************************************************************
 BOX SPECIAL - Výpis článků
******************************************************************************/
.box-special {
    background-color: #ffffff;
    border: 1px solid #cad5df;
    position: relative;
    padding-bottom: 10px;
}
.box-special .list-article.list-article-horizontal-small .image-container {
    min-width: inherit;
}
.box-special .list-article.list-article-horizontal-small .image-container img {
    width: 100%;
    height: auto;
}
.box-special .list-article.list-article-horizontal-small .title-container h2 a {
    text-decoration: none;
    color: #16212d;
}
.box-special .list-article.list-article-horizontal-small .title-container h2 a:hover,
.box-special .list-article.list-article-horizontal-small .title-container h2 a:focus {
    text-decoration: underline;
}
.box-special__header {
    background-color: #ff0000;
    position: relative;
    z-index: 1;
    background-size: cover;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}
.box-special__header a {
    text-decoration: none;
}
.box-special__header a:hover,
.box-special__header a:focus {
    text-decoration: underline;
}
.box-special__header-left {
    float: left;
    width: 50%;
}
.box-special__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
}
.box-special__title a,
.box-special__title:hover,
.box-special__title:focus {
    color: #ffffff;
}
.box-special__subtitle {
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    margin-top: 5px;
}
.box-special__header-right {
    float: left;
    padding-left: 10px;
    border-left: 1px solid #c51a1a;
    width: 50%;
    min-height: 55px;
    box-sizing: border-box;
}
.box-special__header-right a {
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    padding-right: 13px;
    display: block;
    position: relative;
}
.box-special__header-right a:after {
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-left: solid 6px #ffffff;
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    right: 0;
}
.box-special__link {
    margin-bottom: 10px;
    text-align: center;
    display: block;
    font-size: 13px;
    text-decoration: underline;
    line-height: 16px;
    font-weight: 700;
}
.box-special__link:hover {
    text-decoration: none;
}
.box-special__link:after {
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-left: solid 6px #ff0000;
    content: "";
    display: inline-block;
    margin-left: 5px;
}
.box-special--elections .box-special__header {
    background-color: #f4f7f9;
    color: #16212d;
}
.box-special--elections .box-special__title {
    font-size: 22px;
    font-weight: bold;
}
.box-special--elections .box-special__subtitle {
    font-size: 13px;
    font-weight: bold;
}
.box-special--elections .box-special__subtitle a,
.box-special--elections .box-special__title a {
    color: #16212d;
}
.box-special--elections .box-special__link {
    color: #ff0000;
}
.box-special--elections .box-special__header-right {
    border-color: #cad5df;
}
.box-special--elections .box-special__header-right a {
    color: #16212d;
    text-decoration: underline;
}
.box-special--elections .box-special__header-right a:hover,
.box-special--elections .box-special__header-right a:focus {
    text-decoration: none;
}
.box-special--elections .box-special__header-right a:after {
    border-left-color: #ff0000;
}
.box-special--elections .list-article-horizontal-small .title-container h2 a:hover,
.box-special--elections .list-article-horizontal-small .title-container h2 a:focus {
    color: #16212d;
}
.box-special article.list-article-horizontal-small {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 15px 0;
    background: #ffffff;
    border-bottom: 1px solid #cad5df;
}
.box-special article.list-article-horizontal-small:before,
.box-special article.list-article-horizontal-small:after {
    content: "";
    display: table;
}
.box-special article.list-article-horizontal-small:after {
    clear: both;
}
.box-special article.list-article-horizontal-small:first-of-type {
    padding-top: 0;
}
.box-special article.list-article-horizontal-small:last-of-type {
    margin-bottom: 20px;
}
.box-special article.list-article-horizontal-small .image-container {
    width: 49%;
    height: auto;
    min-width: 150px;
    max-height: 180px;
    overflow: hidden;
    float: left;
    margin-right: 0;
}
.box-special article.list-article-horizontal-small .title-container {
    width: 46.5%;
    margin: 0;
    position: relative;
    float: right;
}
.box-special article.list-article-horizontal-small .title-container h2 {
    font-size: 13px;
    line-height: 1.3em;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.box-special article.list-article-horizontal-small .title-container h2 a {
    text-decoration: none;
}
.box-special article.list-article-horizontal-small .title-container h2 a:hover,
.box-special article.list-article-horizontal-small .title-container h2 a:focus {
    text-decoration: underline;
}

/******************************************************************************
 BOXADMIN - Výpis článků odjinud - List article horizontal small
******************************************************************************/
.boxadmin-list-article .title-basic.title-basic--red,
.boxadmin-list-article .title-basic.title-basic--red-left,
.boxadmin-list-article .title-basic.title-basic--red-right,
.boxadmin-list-article .title-basic.title-basic--purple-left,
.boxadmin-list-article .title-basic.title-basic--purple-right {
    position: relative;
    display: block;
    background-color: #ff001e;
    width: 100%;
    height: 40px;
    margin: 0 0 10px;
    padding: 0 0 0 10px;
    font:
        bold 16px/42px "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    box-sizing: border-box;
}
.boxadmin-list-article .title-basic.title-basic--purple-left,
.boxadmin-list-article .title-basic.title-basic--purple-right {
    background-color: #9b59b6;
}
.boxadmin-list-article .title-basic.title-basic--red-right,
.boxadmin-list-article .title-basic.title-basic--purple-right {
    width: calc(100% + 10px);
}

.boxadmin-list-article .title-basic.title-basic--red-left:before,
.boxadmin-list-article .title-basic.title-basic--red-right:before {
    background: transparent url(../../../images/ahacz/ribbon-right-red.png) no-repeat right bottom;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    content: "";
    display: block;
}

.boxadmin-list-article .title-basic.title-basic--red-left:before {
    background: transparent url(../../../images/ahacz/ribbon-left-red.png) no-repeat right bottom;
    left: 0;
}

.boxadmin-list-article .title-basic.title-basic--red-right:before {
    right: 0;
}

.boxadmin-list-article .title-basic.title-basic--purple-left:before,
.boxadmin-list-article .title-basic.title-basic--purple-right:before {
    background: url(../../../images/ahacz/zaha-sprite.png?v=17) no-repeat right bottom;
    width: 20px;
    height: 15px;
    position: absolute;
    bottom: -15px;
    content: "";
    display: block;
}
.boxadmin-list-article .title-basic.title-basic--purple-left:before {
    left: 0;
    background-position: -19px -240px;
}
.boxadmin-list-article .title-basic.title-basic--purple-right:before {
    right: -10px;
    background-position: 0 -240px;
}

.boxadmin-list-article .list-article--horizontal-small {
    position: relative;
    display: inline-block;
    background: #fff;
    width: 100%;
    margin-bottom: 10px;
    clear: both;
}

.boxadmin-list-article .list-article--horizontal-small:hover {
    background: #ff001e;
}

.boxadmin-list-article .list-article--horizontal-small .category-name a {
    padding: 0 10px;
    font-size: 9px;
    font-weight: bold;
    color: #ff001e;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
}

.boxadmin-list-article .list-article--horizontal-small .image-container {
    float: left;
    width: 120px;
    height: 70px;
}

.boxadmin-list-article .list-article--horizontal-small .image-container img {
    width: 110px;
    height: 70px;
}

.boxadmin-list-article .list-article--horizontal-small .title-container {
    float: right;
    width: calc(100% - 125px);
}

.boxadmin-list-article .list-article--horizontal-small .title-container h2 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
}

.boxadmin-list-article .list-article--horizontal-small .title-container h2 a {
    display: block;
    height: 64px;
    margin: 0 0 5px;
    padding: 3px 10px 0 0;
    overflow: hidden;
    color: #343f4b;
    text-decoration: none;
}

.boxadmin-list-article .list-article--horizontal-small:hover .title-container h2 a {
    text-decoration: none;
}

.boxadmin-list-article .list-article--horizontal-small .category-name a:hover,
.boxadmin-list-article .list-article--horizontal-small:hover .category-name a,
.boxadmin-list-article .list-article--horizontal-small:hover .title-container h2 a,
.boxadmin-list-article .list-article--horizontal-small:hover .title-container h2 a:hover {
    color: #fff;
    text-decoration: none;
}

.boxadmin-list-article.purple-right .list-article--horizontal-small:hover {
    background: #734187;
}
.boxadmin-list-article.purple-right .list-article--horizontal-small .category-name a {
    color: #9b59b6;
}

.boxadmin-list-article.purple-right .list-article--horizontal-small .category-name a:hover,
.boxadmin-list-article.purple-right .list-article--horizontal-small:hover .category-name a,
.boxadmin-list-article.purple-right .list-article--horizontal-small:hover .title-container h2 a,
.boxadmin-list-article.purple-right .list-article--horizontal-small:hover .title-container h2 a:hover {
    color: #fff !important;
    text-decoration: none;
}

/******************************************************************************
 BOXADMIN - Výpis článků odjinud - List article vertical medium
******************************************************************************/
.boxadmin-list-article article.list-article--vertical-medium {
    width: 220px;
    height: 255px;
    overflow: hidden;
    clear: none;
    display: inline;
    float: left;
    margin: 0 10px 30px;
    padding: 0 0 15px;
    position: relative;
    background: #fff;
}

.boxadmin-list-article.article-bottom article.list-article--vertical-medium {
    width: 140px;
}

.boxadmin-list-article.article-bottom article.list-article--vertical-medium h2.title {
    font-size: 14px;
    line-height: 15px;
    padding-top: 10px;
}

.boxadmin-list-article article.list-article--vertical-medium .image-container {
    position: relative;
    display: block;
}

.boxadmin-list-article article.list-article--vertical-medium .image-container img {
    max-width: 100%;
}
.boxadmin-list-article article.list-article--vertical-medium .category-name {
    margin-bottom: 5px;
}
.boxadmin-list-article article.list-article--vertical-medium .category-name a {
    padding: 0 10px;
    font-size: 9px;
    font-weight: bold;
    color: #ff001e;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
}

.boxadmin-list-article article.list-article--vertical-medium .category-name a:hover,
.boxadmin-list-article article.list-article--vertical-medium:hover .title-container h2 a {
    color: #dc0032;
    text-decoration: underline;
}

.boxadmin-list-article article.list-article--vertical-medium .title {
    height: 73px;
    padding: 0 10px;
    overflow: hidden;
    color: #343f4b;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
}

.boxadmin-list-article article.list-article--vertical-medium .title a {
    color: #343f4b;
    text-decoration: none;
}

.boxadmin-list-article article.list-article--vertical-medium .title a:hover {
    color: #dc0032;
    text-decoration: underline;
}

.boxadmin-list-article.purple-box article.list-article--vertical-medium .category-name a {
    color: #9b59b6;
}
.boxadmin-list-article.purple-box article.list-article--vertical-medium:hover .title-container h2 a {
    color: #734187;
}

/**************************************************
 STYLES FOR EXCHANGE PANEL
**************************************************/
.exchange-panel {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    font-size: 14px;
}
.exchange-panel .dataimg-triangle-right,
.exchange-panel .dataimg-triangle-up,
.exchange-panel .dataimg-triangle-down {
    display: inline-block;
}
.exchange-panel a {
    font-weight: 700;
    text-decoration: none;
}
.exchange-panel a:hover,
.exchange-panel a:focus {
    text-decoration: underline;
}
.exchange-panel table {
    float: left;
    width: 70%;
    font-size: 12px;
    text-align: center;
}
.exchange-panel table tr {
    border-bottom: 1px solid #dcdcdc;
}
.exchange-panel table tr:last-child {
    border-bottom: 0;
}
.exchange-panel table td {
    padding-top: 4px;
}
.exchange-panel .exchange-panel__header {
    margin-top: 8px;
}
.exchange-panel__flag {
    width: 30px;
    padding-top: 1px;
}
.exchange-panel__flag span {
    display: block;
}
.exchange-panel__currency {
    padding-top: 4px;
    font-weight: 700;
}
.exchange-panel__currency a {
    color: #1e1e1e;
}
.exchange-panel__calculator {
    float: left;
    width: 30%;
    text-align: center;
}
.exchange-panel__calculator .dataimg-calculator {
    width: 65px;
    margin: 0 auto;
}
.container.exchange-panel-container {
    width: unset;
    padding: unset;
}
.exchange-panel__waving-flag {
    margin-left: 8px;
    width: 28px;
    height: 25px;
    background: url(../../../../images/default/exchange-courses/waving-flags.png);
}
.exchange-panel__waving-flag--eu {
    background-position: 0 -2px;
}
.exchange-panel__waving-flag--usa {
    background-position: 0 -30px;
}
.exchange-panel__waving-flag--gbp {
    background-position: 0 -58px;
}

/*
* --------------------
* CNC Mini Lazy Load Placeholder
* https://confluence.cncenter.cz/display/KB/Lazy+load
* --------------------
*/
.loading-placeholder {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}
.loading-placeholder img {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
}
.loading-placeholder.item-loaded {
    padding-bottom: 0 !important;
}
.loading-placeholder.item-loaded img {
    opacity: 1;
}
.loading-placeholder:not(.item-loaded) {
    position: relative;
    height: auto;
    background-color: #e8e8e8;
}
.loading-placeholder:not(.item-loaded)::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
    background-repeat: repeat-y;
    background-size: 100% 1px;
}
.loading-placeholder:not(.item-loaded) img {
    position: absolute !important;
    text-indent: -9999px;
    color: transparent;
}
img.lazyload {
    height: auto;
}

.gam-wrapper {
    display: block;
    clear: both;
}

.embed-mall-tv {
    padding-top: 56.25%;
    position: relative;
    width: 100%;
}

.embed-mall-tv__frame {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
